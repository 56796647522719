import { useContext, useEffect, useState } from "react";
import { Button } from "../styles";
import LogoHg from "../../assets/img/logoHgIcone.png"
import { ModalEstadoContext } from "../../contexts/ModalEstado";
import { ListaDeProcessoSeletivo } from "../../interfaces/processoSeletivo";
import { inscreverProcessoSeletivo, listarProcessoPorId } from "../../services/processoSeletivo";
import { criptografarDado } from "../../helpers";
import Notificacoes from "../Notificacoes";
import { Link, useParams } from "react-router-dom";
import { verificarToken } from "../../middlewares/Token";
import PortalVagas from "../../pages/PortalVagas/PortalVagas";

function ModalDetalhesVagas() {
  const [processando, setProcessando] = useState(false)
  const [candidatoInscrito, setCandidatoInscrito] = useState(false)
  const [erroCandidatoInscrito, setErroCandidatoInscrito] = useState(false)
  const [listaDeProcessoSeletivo, setListaDeProcessoSeletivo] = useState<ListaDeProcessoSeletivo | null>(null)
  const [erro, setErro] = useState(false)

  let idCandidato = localStorage.getItem("idCandidato")
  const { idVaga } = useParams()

  const { exibirModal } = useContext(ModalEstadoContext)

  const inscreverProcesso = async () => {
    setProcessando(true)
    verificarToken()
    try {
      if (idVaga) {
        const idProcessoSeletivoCript = criptografarDado(idVaga.toString())
        await inscreverProcessoSeletivo(idProcessoSeletivoCript)
        setCandidatoInscrito(true)
        setProcessando(false)
        exibirModal("")
        return setTimeout(() => {
          setCandidatoInscrito(false)
        }, 5000)
      }
    } catch (error: any) {
      setProcessando(false)
      if (error.status === 409) {
        setErroCandidatoInscrito(true)
        setTimeout(() => {
          setErroCandidatoInscrito(false)
        }, 5000)
      }
    }
  }

  const listarProcesso = async () => {
    try {
      if (idVaga) {
        setErro(false)
        const idVagaCript = criptografarDado(idVaga)
        const response = await listarProcessoPorId(idVagaCript)
        setListaDeProcessoSeletivo(response.data)
      }
    } catch (error) {
      setErro(true)
      setProcessando(false)
    }
  }

  useEffect(() => {
    listarProcesso()
  }, [idVaga, inscreverProcesso])

  const SeInscrito = listaDeProcessoSeletivo?.processoInscritos.some(
    (inscrito) => inscrito.idCandidato.toString() === idCandidato?.toString()
  );

  return (
    <>
      <PortalVagas />
      <div className='fundoModal'>
        {erro && <Notificacoes cor={"erro"} texto={"Erro ao listar dados do processo seletivo."} />}
        {erroCandidatoInscrito && <Notificacoes cor={"erro"} texto={"Candidato já inscrito no processo seletivo."} />}
        {candidatoInscrito && <Notificacoes cor={"sucesso"} texto={"Candidato inscrito com sucesso."} />}
        <section className="container">
          <section className="cabecalho">
            <div className="informacoes-vaga">
              <div className="logo-img">
                <img
                  src={LogoHg}
                  alt=""
                  className="logo-empresa"
                />
              </div>
              <div>
                <h1 className="titulo-vaga">{listaDeProcessoSeletivo?.titulo || "Titulo não disponivel"}</h1>
                <p className="descricao">{listaDeProcessoSeletivo?.cadastro.nomeFantasia || "Nome da empresa não disponivel"}</p>
                <p className="descricao">{listaDeProcessoSeletivo?.localTrabalhoCidade || "Cidade não disponivel"} - {listaDeProcessoSeletivo?.localTrabalhoEstado || "Estado não disponivel"}</p>
              </div>
            </div>
            <div className="tags-vaga">
              <span className="tag">{listaDeProcessoSeletivo?.modalidadeTrabalho || ""}</span>
              <span className="tag">R$ {listaDeProcessoSeletivo?.salario || ""}</span>
              <span className="tag">{listaDeProcessoSeletivo?.regimeContratacao || ""}</span>
              <span className="tag">{listaDeProcessoSeletivo?.formacaoAcademica || ""}</span>
            </div>
            {!SeInscrito && listaDeProcessoSeletivo ?
              <Button isLoading={processando} cor="continuar" type='button' isCancelar={false} onClick={() => { inscreverProcesso() }}>
                Inscrever-se
              </Button> : <></>}
          </section>
          <section className="conteudo">
            <section className="sobre-vaga">
              <h2 className="subtitulo">Sobre a vaga:</h2>

              <h3 className="subtitulo">Descrição:</h3>
              <p className="descricao">{listaDeProcessoSeletivo?.descricaoCargo || "Descrição não disponivel"}</p>

              <h3 className="subtitulo">Benefícios</h3>
              <p className="descricao">{listaDeProcessoSeletivo?.beneficios || "Beneficios não disponivel"}</p>

              <h3 className="subtitulo">Conhecimentos</h3>
              <div className="container-experiencia">
                <p className="subtitulo">Habilidades desejadas</p>
                <span className="tag-descricao">{listaDeProcessoSeletivo?.requisitos || "Requisitos não disponivel"}</span>
              </div>

              <div className="container-experiencia">
                <p className="subtitulo">Formação Acadêmica</p>
                <span className="tag-descricao">{listaDeProcessoSeletivo?.formacaoAcademica || "Formação Acadêmica não disponivel"}</span>
              </div>

              <div className="container-experiencia">
                <p className="subtitulo">Perfil Comportamental</p>
                <span className="tag-descricao">{listaDeProcessoSeletivo?.perfilComportamental || "Perfil Comportamental não disponivel"}</span>
              </div>

            </section>
            <section>
              <h3 className="subtitulo">Principais atribuições:</h3>
              <p className="descricao">{listaDeProcessoSeletivo?.principaisAtribuicao || "Principais Atribuições não disponivel"}</p>
            </section>
          </section>
          <span className="botoesContainer">
            <Link to="/vagas">
              <Button isLoading={processando} cor="continuar" type='button' isCancelar={false}>
                Fechar
              </Button>
            </Link>
          </span>
        </section>
      </div>
    </>
  );
}

export default ModalDetalhesVagas;