import { useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Vagas from "../../components/Vagas";

function PortalVagas() {

  useEffect(() => {
    document.title = 'Portal de Vagas'
  }, [])

  return (
    <div>
      <Header />
      <Vagas />
      <Footer />
    </div>
  );
}

export default PortalVagas;