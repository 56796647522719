import baseApiGesttor from "../api"

export function listarProcessosSeletivos() {
  return baseApiGesttor.get("/listarProcessosSeletivos")
}

export function listarProcessoPorId(idVagaCript: string) {
  return baseApiGesttor.get(`/listarProcessoSeletivoPorIdHg/${idVagaCript}`)
}

export function inscreverProcessoSeletivo(idProcessoSeletivo: string) {
  return baseApiGesttor.post(`/processoInscrito/${idProcessoSeletivo}`)
}