import { FormEvent, useContext, useEffect, useState } from "react";
import { Button, Loader } from "../styles";
import "./vagas.css"
import ModalDetalhesVagas from "../ModalDetalhesVagas";
import { ModalEstadoContext } from "../../contexts/ModalEstado";
import LogoHg from "../../assets/img/logoHgIcone.png"
import { inscreverProcessoSeletivo, listarProcessosSeletivos } from "../../services/processoSeletivo";
import { ListaDeProcessoSeletivo } from "../../interfaces/processoSeletivo";
import Notificacoes from "../Notificacoes";
import { verificarToken } from "../../middlewares/Token";
import { criptografarDado } from "../../helpers";
import { Link } from "react-router-dom";

function Vagas() {
  const { exibirModal, modal } = useContext(ModalEstadoContext)
  const [processando, setProcessando] = useState(false)
  const [processosSeletivos, setProcessosSeletivos] = useState<ListaDeProcessoSeletivo[]>([])
  const [processosSeletivosFiltrado, setProcessosSeletivosFiltrado] = useState<ListaDeProcessoSeletivo[]>([])
  const [nenhumResultado, setNenhumResultado] = useState(false)

  const [tituloCargo, setTituloCargo] = useState("")
  const [tipoContrato, setTipoContrato] = useState("")
  const [modalidade, setModalidade] = useState("")
  const [nivelInteresse, setNivelInteresse] = useState("")
  const [areaInteresse, setAreaInteresse] = useState("")
  const [estado, setEstado] = useState("")
  const [cidade, setCidade] = useState("")

  const [candidatoInscrito, setCandidatoInscrito] = useState(false)
  const [erroCandidatoInscrito, setErroCandidatoInscrito] = useState(false)

  let idCandidato = localStorage.getItem("idCandidato")

  const listarTodosProcessosSeletivos = async () => {
    setProcessando(true)
    try {
      const response = await listarProcessosSeletivos();

      if (response.status === 200) {
        setProcessosSeletivos(response.data)
        setProcessosSeletivosFiltrado(response.data)
        setProcessando(false)
      } else {
        setNenhumResultado(true)
      }
    } catch (error) {
      setProcessando(false)
      setNenhumResultado(true)
    }
  }

  useEffect(() => {
    listarTodosProcessosSeletivos()
  }, [modal])

  const filtrarProcesso = (e: FormEvent) => {
    e?.preventDefault();
    verificarToken()
    const dadosFiltrados = processosSeletivos.filter(processo => {

      const filtroTituloCargo = tituloCargo === '' || processo.titulo.toString().toLowerCase().includes(tituloCargo.toLowerCase());
      const filtroTipoContrato = tipoContrato === '' || processo.regimeContratacao.toString() === tipoContrato;
      const filtroModalidade = modalidade === '' || processo.modalidadeTrabalho.toString() === modalidade;
      const filtroNivelInteresse = nivelInteresse === '' || processo.formacaoAcademica.toString() === nivelInteresse;
      const filtroAreaInteresse = areaInteresse === '' || processo.area.toString() === areaInteresse;
      const filtroEstado = estado === '' || processo.localTrabalhoEstado.toString() === estado;
      const filtroCidade = cidade === '' || processo.localTrabalhoCidade.toString().toLowerCase().includes(cidade.toLowerCase());

      return filtroTituloCargo && filtroTipoContrato && filtroModalidade && filtroNivelInteresse && filtroAreaInteresse && filtroEstado && filtroCidade;
    });

    if (dadosFiltrados.length === 0) {
      setNenhumResultado(true)
      return setTimeout(() => {
        setNenhumResultado(false)
      }, 5000)
    }
    setProcessosSeletivosFiltrado(dadosFiltrados);
  }


  const handleInscricao = async (idProcessoSeletivo: number) => {
    setProcessando(true)
    verificarToken()
    try {
      const idProcessoSeletivoCript = criptografarDado(idProcessoSeletivo.toString())
      await inscreverProcessoSeletivo(idProcessoSeletivoCript)
      setCandidatoInscrito(true)
      setProcessando(false)
      listarTodosProcessosSeletivos()

      return setTimeout(() => {
        setCandidatoInscrito(false)
      }, 5000)
    } catch (error: any) {
      setProcessando(false)
      if (error.status === 409) {
        setErroCandidatoInscrito(true)

        setTimeout(() => {
          setErroCandidatoInscrito(false)
        }, 5000)
      }
    }
  }

  return (
    <>
      {nenhumResultado && <Notificacoes cor={"erro"} texto={"Nenhum processo seletivo encontrado."} />}
      {erroCandidatoInscrito && <Notificacoes cor={"erro"} texto={"Candidato já inscrito no processo seletivo."} />}
      {candidatoInscrito && <Notificacoes cor={"sucesso"} texto={"Candidato inscrito com sucesso."} />}
      <section className="container-principal">
        <aside className="filtros">
          <span className="opcoes-filtro">
            <span className="item-filtro">
              <input type="text" className="selecao-filtro" placeholder="Filtrar pelo Titulo do Cargo" onChange={(e) => setTituloCargo(e.target.value)} />
            </span>
            <span className="item-filtro">
              <select className="selecao-filtro" defaultValue='' onChange={(e) => setTipoContrato(e.target.value)}>
                <option value="">Tipo de Contrato</option>
                <option value="Estágio">Estágio</option>
                <option value="Clt">Clt</option>
                <option value="Contrato">Contrato</option>
                <option value="Diarista">Diarista</option>
                <option value="Outros">Outros</option>
              </select>
            </span>
            <span className="item-filtro">
              <select className="selecao-filtro" defaultValue='' onChange={(e) => setModalidade(e.target.value)}>
                <option value="">Modalidade</option>
                <option value="Presencial">Presencial</option>
                <option value="Home-Office">Home-Office</option>
                <option value="Híbrido">Híbrido</option>
              </select>
            </span>
            <span className="item-filtro">
              <select className="selecao-filtro" defaultValue='' onChange={(e) => setNivelInteresse(e.target.value)}>
                <option value="">Nível de Interesse</option>
                <option value="Nível Fundamental">Nível Fundamental</option>
                <option value="Nível Médio">Nível Médio</option>
                <option value="Nível Superior">Nível Superior</option>
                <option value="Pós - Graduação">Pós - Graduação</option>
                <option value="Mestrado">Mestrado</option>
                <option value="Doutorado">Doutorado</option>
                <option value="Phd">Phd</option>
              </select>
            </span>
            <span className="item-filtro">
              <select className="selecao-filtro" defaultValue='' onChange={(e) => setAreaInteresse(e.target.value)}>
                <option value="">Área de Interesse</option>
                <option value="Tecnologia Da Informação">Tecnologia Da Informação</option>
                <option value="Saúde">Saúde</option>
                <option value="Engenharia">Engenharia</option>
                <option value="Finanças e Contabilidade">Finanças e Contabilidade</option>
                <option value="Administrativo">Administrativo</option>
                <option value="Atendimento">Atendimento</option>
                <option value="Marketing">Marketing</option>
                <option value="Vendas">Vendas</option>
                <option value="Educação">Educação</option>
                <option value="Recursos Humanos">Recursos Humanos</option>
                <option value="Construção Civil">Construção Civil</option>
                <option value="Logística">Logística</option>
              </select>
            </span>
            <span className="item-filtro">
              <select className="selecao-filtro" defaultValue='' onChange={(e) => setEstado(e.target.value)}>
                <option value="">Estados</option>
                <option value="Acre">Acre</option>
                <option value="Alagoas">Alagoas</option>
                <option value="Amapá">Amapá</option>
                <option value="Amazonas">Amazonas</option>
                <option value="Bahia">Bahia</option>
                <option value="Ceará">Ceará</option>
                <option value="Distrito Federal">Distrito Federal</option>
                <option value="Espírito Santo">Espírito Santo</option>
                <option value="Goiás">Goiás</option>
                <option value="Maranhão">Maranhão</option>
                <option value="Mato Grosso">Mato Grosso</option>
                <option value="Mato Grosso do Sul">Mato Grosso do Sul</option>
                <option value="Minas Gerais">Minas Gerais</option>
                <option value="Pará">Pará</option>
                <option value="Paraíba">Paraíba</option>
                <option value="Paraná">Paraná</option>
                <option value="Pernambuco">Pernambuco</option>
                <option value="Piauí">Piauí</option>
                <option value="Rio de Janeiro">Rio de Janeiro</option>
                <option value="Rio Grande do Norte">Rio Grande do Norte</option>
                <option value="Rio Grande do Sul">Rio Grande do Sul</option>
                <option value="Rondônia">Rondônia</option>
                <option value="Roraima">Roraima</option>
                <option value="Santa Catarina">Santa Catarina</option>
                <option value="São Paulo">São Paulo</option>
                <option value="Sergipe">Sergipe</option>
                <option value="Tocantins">Tocantins</option>
              </select>
            </span>
            <span className="item-filtro">
              <input type="text" className="selecao-filtro" placeholder="Filtrar pelo nome da Cidade" onChange={(e) => setCidade(e.target.value)} />
            </span>
          </span>
          <span className="botoes-container">
            <Button isCancelar={false} type="button" cor="continuar" isLoading={processando} onClick={(e) => { filtrarProcesso(e) }}>
              Filtrar
            </Button>
          </span>
        </aside>
        <section className="lista-vagas">
          {processosSeletivos && processosSeletivosFiltrado.map((processo) => (
            <span key={processo.id} className="item-vaga">
              <img
                src={LogoHg}
                alt=""
                className="logo-empresa"
              />
              <span className="detalhes-vaga">
                <h3 className="titulo-vaga">{processo.titulo}</h3>
                <p className="descricao">{processo.cadastro.nomeFantasia}</p>
                <p className="descricao">{processo.localTrabalhoCidade} - {processo.localTrabalhoEstado}</p>
                <span className="tags-vaga">
                  <span className="tag">{processo.modalidadeTrabalho}</span>
                  <span className="tag">{processo.formacaoAcademica}</span>
                  <span className="tag">R$ {processo.salario}</span>
                  <span className="tag">{processo.regimeContratacao}</span>
                </span>
              </span>
              <span className="acoes-vaga">
                <div className="botoesContainer">
                  <Link to={`/vagas/${processo.id}`} className="botao-detalhes" >Detalhes da vaga</Link>
                  {!processo.processoInscritos.some(
                    (inscrito) => inscrito.idCandidato.toString() === idCandidato?.toString()
                  ) ?
                    <Button isLoading={processando} cor="continuar" type='button' isCancelar={false} onClick={() => handleInscricao(processo.id)}>
                      {!processando && 'Inscrever-se'}
                      {processando && <Loader isLoading={processando} />}
                    </Button>
                    :
                    <Button isLoading={processando} cor="contratar" type='button' isCancelar={false}>
                      {!processando && 'Inscrito'}
                    </Button>}
                </div>
              </span>
            </span>
          ))}
        </section>
      </section>
    </>
  );
}

export default Vagas;